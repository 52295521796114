<!-- eslint-disable vue/no-v-html -->
<template>
  <div ref="el" class="slider">
    <div ref="list" class="slider-inner">
      <template v-for="(item, i) in content" :key="`slider-items-${i}`">
        <div ref="slide" class="slider__item-outer">
          <div class="slider__item">
            <div class="slider__item__picture-outer">
              <GPicture
                ref="picture"
                :content="item.picture"
                class="slider__item__picture cover"
              />
            </div>
            <div class="wrapper">
              <div class="slider__content">
                <div class="slider__infos">
                  <h2 class="slider__title">
                    {{ item.title }}
                  </h2>

                  <div
                    v-if="item.text"
                    class="slider__text"
                    v-html="item.text"
                  ></div>

                  <Action
                    v-if="item.link"
                    :content="item.link"
                    class="slider__cta"
                  />
                </div>

                <div v-if="content.length > 1" class="slider__controls">
                  <button
                    v-for="(cta, j) in content"
                    :key="`slider-cta-${j}`"
                    class="slider__controls__cta"
                    :class="{ 'is-active': actual === j }"
                    @click="prevSlide(j)"
                  >
                    <span>
                      {{ j + 1 }}
                      <span class="h-visually-hidden">{{ cta.title }}</span>
                    </span>

                    <div class="slider__progress">
                      <div
                        ref="progressBar"
                        class="slider__progress__bar"
                      ></div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import type { Link, Picture } from '@/types'

export interface HomeSliderItem {
  title: string
  text?: string
  picture: Picture
  link: Link
}
</script>

<script setup lang="ts">
import Slidy from 'epic-slidy'
import { gsap } from 'gsap'
import { onMounted, onUnmounted, ref } from 'vue'

import type { TransitionInfos } from 'epic-slidy'

defineProps({
  content: {
    type: Object as PropType<HomeSliderItem[]>,
    required: true,
  },
})

let slider: Slidy
const el = ref<null | HTMLElement>(null)
const list = ref<null | HTMLElement>(null)
const slide = ref()
const progressBar = ref()
const actual = ref(0)

onMounted(() => {
  const updateProgress = (index: number) => {
    if (progressBar.value) {
      reset()
      gsap.to(progressBar.value, 8, {
        backgroundPosition: '-100%',
        ease: 'linear',
        onComplete: () => {
          // console.log('onComplete', index)
          slider.slideTo(index + 1, 'auto')
        },
      })
    }
  }

  const reset = () => {
    if (!progressBar.value) {
      return
    }
    gsap.killTweensOf(progressBar.value)
    gsap.set(progressBar.value, { clearProps: 'all' })
  }

  const transition = (
    currentSlide: gsap.TweenTarget,
    newSlide: gsap.TweenTarget,
    { currentIndex, newIndex, direction }: TransitionInfos
  ) => {
    const tl = gsap.timeline({
      defaults: {
        ease: 'power4.inOut',
      },
    })

    // console.log('slider & direction', newIndex, direction)

    actual.value = newIndex

    const current = {
      pictures: slide?.value![currentIndex].querySelector('.picture'),
      infos: slide?.value![currentIndex].querySelector('.slider__infos'),
    }

    const next = {
      pictures: slide?.value![newIndex].querySelector('.picture'),
      infos: slide?.value![newIndex].querySelector('.slider__infos'),
    }

    tl.add('transition')
      .set([currentSlide, newSlide], {
        opacity: 1,
        height: '100%',
      })
      .set(current.infos, {
        opacity: 1,
        zIndex: 2,
      })
      .set(next.infos, {
        y: 10,
        opacity: 0,
        zIndex: 3,
      })
      .set(next.pictures, {
        scale: 1.05,
        opacity: 0,
      })
      .to(
        current.pictures,
        {
          duration: 1,
          opacity: 0,
        },
        'transition'
      )
      .to(
        next.pictures,
        {
          duration: 1,
          scale: 1,
          opacity: 1,
        },
        'transition'
      )
      .to(
        current.infos,
        {
          y: -10,
          duration: 0.7,
          opacity: 0,
        },
        'transition'
      )
      .to(
        next.infos,
        {
          y: 0,
          duration: 0.7,
          opacity: 1,
        },
        '.2'
      )

    updateProgress(newIndex)

    return tl.then()
  }

  slider = new Slidy(list.value as HTMLElement, {
    click: false,
    swipe: true,
    pagination: false,
    pause: true,
    nav: false,
    loop: true,
    transition,
  })

  setTimeout(() => {
    slider.init()
  }, 10)

  const onResize = () => {
    slider.destroy()
    slider.init()
  }

  window.addEventListener('resize', onResize)
  updateProgress(0)
})

const prevSlide = (index: number) => {
  slider.slideTo(index, 'auto')
}

onUnmounted(() => {
  slider.destroy()
})
</script>

<style lang="scss" scoped>
.slider {
  position: relative;
}

.slider__item-outer {
  position: absolute;
  width: 100%;
  opacity: 0;
  pointer-events: none;

  &.is-active {
    opacity: 1;
    pointer-events: initial;
  }
}

.slider__item {
  position: relative;
}

.slider__item__picture-outer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 9.5rem);

  @include mq($until: m) {
    height: calc(90vh - 9.5rem);
    aspect-ratio: 40/56;
  }
}

.slider__item__picture {
  @include get-all-space;
}

.slider__infos {
  @extend %br-default;

  // prettier-ignore
  @include fluid(padding,(xxs: 2.4rem, l: 3.6rem,));

  position: absolute;
  bottom: $spacing * 2;
  left: 0;
  width: calc(100vw - ($spacing * 3));
  background-color: $c-white;

  @include mq($until: m) {
    bottom: calc($spacing * 2 + 0.8rem);
    left: 50%;
    transform: translateX(-50%);
  }

  @include mq(m) {
    max-width: col(10);
  }
}

.slider__title {
  @extend %fraunces-spec;
  @extend %fw-light;

  // prettier-ignore
  @include fluid(font-size, (xxs: 3.2rem, xxl: 6rem));

  // prettier-ignore
  @include fluid(line-height, (xxs: 4.2rem, xxl: 6.8rem));

  margin-block: 0;
  color: $c-green-abr;
}

.slider__text {
  display: none;
  margin-top: 1.6rem;
  color: $c-green-abr;
  font-size: 1.6rem;

  @include mq(m) {
    display: block;
  }
}

.slider__cta {
  margin-top: 2.4rem;
}

.slider__controls {
  position: absolute;
  z-index: 2;
  right: 50%;
  bottom: $spacing;
  display: flex;

  @include mq($until: m) {
    gap: 0.4rem;
    transform: translateX(50%);
  }

  @include mq(m) {
    gap: 0.2rem;
    right: 0;
    bottom: $spacing * 2;
  }
}

.slider__controls__cta {
  @extend %button-nostyle;

  position: relative;
  overflow: hidden;
  transition: all 0.2s ease-in-out;

  @include mq($until: m) {
    width: 0.8rem;
    height: 0.8rem;
    background-color: $c-grey-light;
    border-radius: 50%;

    span {
      display: none;
    }

    &:hover,
    &.is-active {
      background: $c-green-abr;
    }
  }

  @include mq(m) {
    padding-inline: 1.4rem;
    height: 3rem;
    color: rgba($c-black, 30%);
    font-size: 1.6rem;
    background: rgba($c-white, 5%);
    backdrop-filter: blur(20px);
    border: 1px solid transparent;
    border-radius: 8px;
    cursor: pointer;

    &:hover,
    &.is-active {
      color: $c-black;
      background: $c-white;
      border: 1px solid $c-grey-dark;
    }
  }
}

.slider__progress {
  position: absolute;
  z-index: 9;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;

  .slider__controls__cta.is-active & {
    @include mq(m) {
      opacity: 1;
    }
  }
}

.slider__progress__bar {
  z-index: 10;
  width: 100%;
  height: 0.2rem;

  // prettier-ignore
  background-image:
    linear-gradient(
      to left,
      $c-green-abr 50%,
      $c-gray-lightest 50%,
      $c-gray-lightest 100%
    );
  background-size: 200%;
}

.slider__content {
  position: relative;
  width: 100%;
}
</style>
